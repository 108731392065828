import { useRef } from "react";

function VerificationCodeInput({ code, setCode, setDisabledHandler }) {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const setNewCode = (value, index) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    setDisabledHandler(newCode);
  };
  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^\d$/.test(value)) {
      setNewCode(value, index);

      if (index < inputRefs.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === "") {
      setNewCode("", index);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      inputRefs[index - 1].current.focus();
      setNewCode("", index);
    }
  };

  return (
    <div className={"flex space-x-4"}>
      {code.map((digit, index) => (
        <input
          className={
            "inline-block border-2 border-primary-500 bg-dark-50 dark:bg-dark-700 w-full text-xl font-bold text-center placeholder:text-dark-400 dark:text-white outline-none focus:ring-2 focus:ring-primary-800 p-4 rounded-md"
          }
          key={index}
          ref={inputRefs[index]}
          type="text"
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </div>
  );
}

export default VerificationCodeInput;
