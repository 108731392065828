import Container from "@/components/ui/Container/Container";
import {
  ClipboardDocumentCheckIcon,
  CurrencyDollarIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import bg from "@/assets/images/benefitsBg.jpeg";
import CallToAction from "@/components/common/CallToAction";
import { useDataContext } from "@/context/DataContext";
import {cn} from "@/utils";

const BenefitsSection = () => {
  const { data } = useDataContext();

  return (
    <section
      className="py-16 bg-center bg-cover relative"
      style={{ backgroundImage: `url("${bg}")` }}
    >
      <div className={
        cn(
          'absolute top-0 left-0 w-full py-16 lg:py-32 h-full bg-gradient-to-bl dark:from-dark-950 dark:to-dark-950/70  z-0',
          "from-dark-50 to-dark-50/70"

        )
      }></div>
      <Container className="relative">
        <h2 className="text-3xl lg:text-5xl text-center font-bold mb-16">
          С нами Вы получаете выгоду
        </h2>
        <div className="-mx-4">
          <ul className="flex flex-wrap justify-between w-full ">
            <li className="px-4 w-full lg:w-1/3 text-center mb-10 lg:mb-0">
              <span className="p-3 bg-accent-200 rounded-full inline-block text-accent-700 shadow-lg shadow-accent-200/50">
                <CurrencyDollarIcon className="w-12 h-12" />
              </span>
              <p className="text-xl mt-6">
                Экономия около 30% по тому, что мы работаем без посредников.
              </p>
            </li>
            <li className="px-4 w-full lg:w-1/3 text-center mb-10 lg:mb-0">
              <span className="p-3 bg-accent-200 rounded-full inline-block text-accent-700 shadow-lg shadow-accent-200/50">
                <ClipboardDocumentCheckIcon className="w-12 h-12" />
              </span>
              <p className="text-xl mt-6">Тщательная проверка агрегатов.</p>
            </li>
            <li className="px-4 w-full lg:w-1/3 text-center">
              <span className="p-3 bg-accent-200 rounded-full inline-block text-accent-700 shadow-lg shadow-accent-200/50">
                <ShieldCheckIcon className="w-12 h-12" />
              </span>
              <p className="text-xl mt-6">
                Гарантия сохранности груза при перевозке.
              </p>
            </li>
          </ul>
        </div>
        <div className="md:w-1/2 mx-auto w-full text-center">
          <CallToAction
            text={
              <span className={"text-xl"}>
                {data.isRun
                  ? "Напишите нам в WhatsApp и получите скидку 10%"
                  : "Оставьте заявку и получите скидку 10%"}
              </span>
            }
          />
        </div>
      </Container>
    </section>
  );
};
export default BenefitsSection;
