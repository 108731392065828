import Container from "@/components/ui/Container/Container";
import ProductsList from "@/components/common/ProductsList";
import trans from "@/content/trans";
import dvs from "@/content/dvs";
import spec from "@/content/spec";
import { NavLink, useParams } from "react-router-dom";
import "./ProductsPage.css";
const productsCollection = {
  dvs: { title: "Двигатели внутреннего сгорания.", products: dvs },
  trans: { title: "Коробки передач всех типов.", products: trans },
  spec: {
    title: "Узлы и агрегаты на специальную и коммерческую технику.",
    products: spec,
  },
};
const navigation = [
  { name: "Все", href: "/products" },
  { name: "Двигатели внутреннего сгорания", href: "/products/dvs" },
  { name: "Коробки передач", href: "/products/trans" },
  { name: "Спец. техника", href: "/products/spec" },
];
const ProductsPage = () => {
  const { slug } = useParams();
  return (
    <>
      <section className="py-16">
        <Container>
          <h2 className="text-4xl font-bold tracking-tight mb-4 dark:text-white sm:text-6xl">
            {productsCollection[slug]?.title ?? "Ассортимент"}
          </h2>
          <ul className="flex flex-wrap md:space-y-0 items-start -mx-2 mt-6">
            {navigation.map((item) => {
              return (
                <li className="p-2" key={item.href}>
                  <NavLink key={item.href} className="navLink" to={item.href}>
                    {item.name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <ProductsList
            products={
              productsCollection[slug]?.products ?? [...dvs, ...trans, ...spec]
            }
          />
        </Container>
      </section>
    </>
  );
};
export default ProductsPage;
