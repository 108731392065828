// import WhatsApp from "@/components/common/WhatsApp";
import Promo from "@/components/common/Promo";
// import { useDataContext } from "@/context/DataContext";
import DynamicButton from "@/components/ui/DynamicButton";

const ProductItem = ({ product, setShowModal }) => {
  // const { data } = useDataContext();
  return (
    <div className="h-full flex flex-col">
      <img
        src={product.img}
        alt={product.title}
        className="object-cover rounded-t-lg h-full object-center"
      />

      <div className="rounded-b-lg bg-dark-200 border-dark-300 dark:bg-dark-800 border dark:border-dark-700 px-4 py-6 text-left ">
        <div>
          <p className="font-bold"> {product.title}</p>
          <small className="text-dark-300"> {product.text}</small>

          <div className="flex text-accent-500 items-center mt-3">
            <span className="mr-2">Цена:</span>

            <p className="font-bold">{Number(product.price).toFixed(2)}₽</p>
          </div>
        </div>
        <small className="mt-5 block">
          Успей получить скидку 10%
          <Promo />
        </small>
        {/*{data.isRun ? (*/}
        {/*  <small>Для заказа пишите нам в Whatsapp</small>*/}
        {/*) : (*/}
        {/*  <>*/}
        <DynamicButton
          onClick={() => setShowModal(true)}
          type="button"
          className="flex items-center justify-center gap-x-2 mt-5 px-6 py-4 w-full"
        >
          Заказать
        </DynamicButton>
        {/*    <small className="font-normal inline-block mt-4 ">*/}
        {/*      Или напишите нам в WhatsApp*/}
        {/*    </small>*/}
        {/*  </>*/}
        {/*)}*/}
        {/*<WhatsApp*/}
        {/*  type="cta"*/}
        {/*  size="sm"*/}
        {/*  text={`Здавствуйте, интересует ${product.title} за ${Number(*/}
        {/*    product.price,*/}
        {/*  ).toFixed(2)}₽`}*/}
        {/*/>*/}
      </div>
    </div>
  );
};
export default ProductItem;
