import "./App.css";
import React, {Fragment, useEffect} from "react";
import {Route, Routes} from "react-router-dom";

import {useDataContext} from "@/context/DataContext";
import Http from "@/services/Http/Http.service";
import {YMInitializer} from "react-yandex-metrika";

import Loader from "@/components/common/Loader";

import HomePage from "@/components/pages/HomePage";
import Layout from "@/components/layout/Layout";
import NotFound from "@/components/pages/NotFound";
import Contacts from "@/components/pages/Contacts";
import AboutUs from "@/components/pages/AboutUs";
import Delivery from "@/components/pages/Delivery";
import ProductsPage from "@/components/pages/ProductsPage";
import {getParamsWithPrefix, setToLocalStorage} from "@/utils";
import OrderLayout from "@/components/layout/OrderLayout";
import Order from "@/components/pages/Order";
import OrderTrack from "@/components/pages/OrderTrack";
import Payment from "@/components/pages/Payment";

function App() {
  const {data, setData} = useDataContext();
  const utms = getParamsWithPrefix("utm_");
  if (Object.keys(utms).length !== 0) {
    setToLocalStorage("utms", utms);
  }

  const getData = () => {
    if (!data) {
      Http.get("/site-data.php").then((response) => {
        const data = response.data;
        setData(data);
      });
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  return data ? (
    <>
      {data?.ymCode && (
        <YMInitializer
          accounts={[Number(data?.ymCode)]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
        />
      )}

      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<HomePage/>}/>
          <Route path="/about-us" element={<AboutUs/>}/>
          <Route path="/products" element={<ProductsPage/>}/>
          <Route path="/contacts" element={<Contacts/>}/>
          <Route path="/delivery" element={<Delivery/>}/>
          <Route path="/products/:slug" element={<ProductsPage/>}/>
        </Route>
        <Route path="/order" element={<OrderLayout/>}>
          <Route index element={<Order/>}/>
          <Route path=":uuid" element={<OrderTrack/>}/>
        </Route>
        <Route path="/payment/:uuid" element={<Payment/>}/>

        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </>
  ) : (
    <Loader/>
  );
}

export default App;
