import { Dialog } from "@headlessui/react";
import classes from "@/components/pages/Order/Order.module.css";
import Card from "@/components/ui/Card";
import VerificationCodeInput from "@/components/ui/VerificationCodeInput";
import { useState } from "react";

export default function OrderModal({ isVerif, setIsVerif, phone, uuid }) {
  const [code, setCode] = useState(["", "", "", ""]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(false);
  const setDisabledHandler = (newCode) => {
    setError(false);
    return newCode.join("").length === 4
      ? setIsDisabled(false)
      : setIsDisabled(true);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const verificationPhon = phone.slice(-4);
    const verificationCode = code.join("");
    if (verificationPhon === verificationCode) {
      setIsVerif((prev) => {
        return { ...prev, [uuid]: true };
      });
    } else {
      setError("Неверный код");
    }
  };
  return (
    <Dialog
      open={isVerif}
      onClose={() => {}}
      as="div"
      className="relative z-[999992]"
    >
      <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Card
            as={Dialog.Panel}
            onSubmit={(e) => {}}
            className=" w-full  max-w-md px-5 py-8"
          >
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 mb-3 dark:text-dark-100 "
            >
              Пройдите верификацию
            </Dialog.Title>
            <form onSubmit={onSubmitHandler}>
              {error && (
                <div className="text-rose-500 text-sm mb-3">{error}</div>
              )}
              <legend className="text-sm mb-3">
                Введите не достающие цифри {phone.slice(0, -4) + "****"}
              </legend>
              <div className="relative">
                <VerificationCodeInput
                  code={code}
                  setCode={setCode}
                  setDisabledHandler={setDisabledHandler}
                />
              </div>
              <button
                disabled={isDisabled}
                type="submit"
                className={`${classes.btn} w-full justify-center mt-5 px-6 py-4 disabled:opacity-50`}
              >
                Верифицироваться
              </button>
            </form>
          </Card>
        </div>
      </div>
    </Dialog>
  );
}
