import Promo from "@/components/common/Promo";
import Form from "@/components/common/Form";

const CallToAction = ({ text }) => {
  return (
    <div className="mt-10 rounded-lg bg-dark-50 text-dark-950 dark:bg-dark-800 dark:text-white border dark:border-dark-700 border-dark-300 px-5 py-8 font-bold">
      <Promo text={text} />
      <>
        <Form />
      </>
    </div>
  );
};
export default CallToAction;
