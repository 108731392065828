import Container from "@/components/ui/Container/Container";
import spec from "./images/1.jpeg";
import dvs from "./images/2.jpeg";
import trans from "./images/3.jpeg";
import HomeProductsItem from "./HomeProductsItem";
import classes from "./HomeProducts.module.css";

const HomeProducts = () => {
  return (
    <section className="py-16 dark:bg-dark-900 bg-dark-100">
      <Container className="text-center ">
        <h3 className="text-4xl font-bold mb-4">
          Широкий ассортимент запчастей
        </h3>
        <p className="text-lg lg:text-2xl">
          контрактные двигатели в сборе, коробки передач, агрегаты для грузовой,
          легковой и специализированной коммерческой техники.
        </p>
        <div className={classes.HomeProductsRow}>
          <div className={classes.HomeProductsCol}>
            <HomeProductsItem
              image={spec}
              title=" Узлы и агрегаты на специальную и коммерческую технику."
              text="Безупречный подход к оценке остаточного ресурса в подборе
                    агрегатов на коммерческую технику, это наш ключевой фактор
                    успеха. Зачастую гарантийных обращений на протяжении всего
                    года меньше 3% от общей массы продаваемых деталей. Как правило
                    решается оперативно."
              link="/products/spec"
            />
          </div>
          <div className={classes.HomeProductsCol}>
            <HomeProductsItem
              image={dvs}
              title="Двигатели внутреннего сгорания."
              text="Мы поставляем любые двигатели уже более трех лет,
                    в наш ассортимент входят не только автомобильные,
                    но и двигатели для малой авиации, специальной, сельскохозяйственной и коммерческой техники."
              link="/products/dvs"
            />
          </div>
          <div className={classes.HomeProductsCol}>
            <HomeProductsItem
              image={trans}
              title="Коробки передач всех типов."
              text="Это не менее востребованная ниша в контрактных агрегатах,
                    и здесь мы уже наработали достойный потенциал в рынке.
                    Доля гарантийных обращений от общего объема продаж всего 0,3-0,6%."
              link="/products/trans"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
export default HomeProducts;
