import { cn } from "@/utils";

export default function Card({
  as: Element = "div",
  children,
  className,
  ...props
}) {
  return (
    <Element
      className={cn(
        "rounded-lg dark:bg-dark-800 border dark:border-dark-700 p-5 bg-dark-200 border-dark-300",
        className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
}
