import { cn } from "@/utils";

const HeaderOverLayer = () => {
  return (
    <div
      className={cn(
        "w-full h-full absolute top-0 left-0 z-[1] ",
        "bg-gradient-to-b dark:from-dark-950/90 dark:via-dark-950/60 dark:to-dark-950",
        "from-dark-50/70 via-dark-50/60 to-dark-50",
      )}
    />
  );
};

export default HeaderOverLayer;
