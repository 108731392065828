import Container from "@/components/ui/Container/Container";
import { navigation } from "@/router";
import { NavLink } from "react-router-dom";
import { useDataContext } from "@/context/DataContext";
// import WhatsApp from "@/components/common/WhatsApp";

const Footer = () => {
  const { data } = useDataContext();

  return (
    <footer className="py-16 bg-gradient-to-bl dark:from-dark-900 dark:to-dark-800 from-dark-400 to-dark-100">
      <Container>
        <div className="grid lg:grid-cols-2 gap-6">
          <hr className="border-dark-700 lg:hidden" />

          <div>
            <nav className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 text-sm font-semibold">
              {navigation.map((item) => {
                return (
                  <NavLink
                    key={item.href}
                    className="dark:text-dark-300 dark:hover:text-dark-500 text-dark-700 hover:text-dark-800"
                    to={item.href}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </nav>
            <p className="my-3">
              2021 © Продажа контрактных двигателей и коробок передач.
            </p>
            <small className="text-dark-500">{data.company}</small>
          </div>
          {/*<div className="order-first lg:order-last flex flex-col justify-center items-start lg:items-end">*/}
          {/*  <span className="mb-3">Напишите нам в WhatsApp</span>*/}
          {/*  <WhatsApp type="header" />*/}
          {/*</div>*/}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
