const StepItem = ({ svg, title, text, count, isLast }) => {
  return (
    <div
      className={`col w-full lg:w-1/4 lg:basis-1/4 ${
        !isLast ? "mb-10 lg:mb-0" : ""
      }`}
    >
      <p className="text-center mb-3 text-dark-500 dark:text-dark-400">ШАГ</p>
      <div className="step-icon-box mx-auto p-3 bg-primary-200 rounded-full inline-block text-primary-700 shadow-lg shadow-primary-200/50">
        <span className="">{count}</span>
      </div>
      <span className="step-title">{title}</span>
      <p className="step-text">{text}</p>
    </div>
  );
};
export default StepItem;
