import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

const Modal = ({ isOpen, closeHandler, modalTitle, children }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999992]" onClose={closeHandler}>
          <div className="fixed inset-0 top-0 w-full h-full backdrop-blur" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  max-w-lg transform relative overflow-hidden rounded-2xl dark:bg-dark-800 bg-dark-200 border border-dark-300 dark:border-dark-700 p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    type="button"
                    className="rounded-md border top-3 right-3 absolute  transition-colors border-transparent bg-dark-300 hover:bg-dark-400 dark:bg-dark-700 p-2 dark:text-dark-300 dark:hover:bg-dark-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-dark-500 focus-visible:ring-offset-2"
                    onClick={closeHandler}
                  >
                    <XMarkIcon className="w-5 h-5" />
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 mb-5 dark:text-dark-100 "
                  >
                    {modalTitle}
                  </Dialog.Title>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
