import bg from "./bg.avif";
import Container from "@/components/ui/Container/Container";
import { useDataContext } from "@/context/DataContext";
const stats = [
  { name: "Успешных доставок", value: "5000+" },
  { name: "Довольных клиентов", value: "6000+" },
  { name: "Зарубежных контрагентов", value: "15" },
  { name: "складов по РФ", value: "8" },
];
const AboutHero = () => {
  const { data } = useDataContext();

  return (
    <section className="py-16 relative">
      <img
        src={bg}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-20 dark:opacity-100"
      />
      <Container>
        <div className="relative isolate overflow-hidden py-6 lg:py-24 px-6 ">
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-accent-500 to-primary-500 opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-accent-500 to-primary-500 opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div>
            <div>
              <h2 className="text-4xl font-bold tracking-tight mb-4 text-dark-950 dark:text-white sm:text-6xl">
                О нас
              </h2>
              <span className="inline-block rounded-xl lg:rounded-full bg-primary-600 dark:bg-dark-800/50 py-2 px-4 text-xs font-medium text-white dark:text-dark-400 ring-1 ring-inset ring-dark-500/10 ">
                {data.company}
              </span>
              <p className="mt-6 text-sm text-dark-800 dark:text-gray-300">
                Наша компания занимается поставкой в Россию и соседние страны
                гарантийных и рабочих двигателей, коробок передач разных типов
                на различные виды автомобилей и прочей техники. В нашей компании
                клиент получает безупречную гарантию, чаще всего, которой не
                приходится пользоваться, ведь в принципах работы заложен подход
                в избрании максимально сохранившихся агрегатов. В этом мы
                преуспели, мы развиваемся и в других направлениях, таких как
                навесное оборудование, КМУ и шнековые машины. Приобретая
                запчасти у нас, вы приобретаете качество, надежность и ресурс!
                Спасибо, что выбрали именно нас!!!
              </p>
            </div>

            <div className="mt-10">
              <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col-reverse">
                    <dt className="text-base leading-7 text-dark-800 dark:text-gray-300">
                      {stat.name}
                    </dt>
                    <dd className="text-2xl font-bold leading-9 tracking-tight text-dark-900 dark:text-white">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutHero;
