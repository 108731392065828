import Container from "@/components/ui/Container/Container";
import Card from "@/components/ui/Card";
import { timestampToDate } from "@/utils";
import { CheckIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
const statusIcon = (status) => {
  return status === "success" ? (
    <span className="p-1 bg-green-200 rounded-full inline-block text-green-700 ">
      <CheckIcon className="w-4 h-4" />
    </span>
  ) : (
    <span className="p-1 bg-rose-200 rounded-full inline-block  text-rose-700 ">
      <XMarkIcon className="w-4 h-4" />
    </span>
  );
};
export default function OrderTrackPayments({ payments = [] }) {
  return (
    <Container className="mb-20">
      <p className="mb-2">Транзакции</p>
      <Card as="section">
        <div className={"overflow-y-auto"}>
          <table className="border-collapse table-auto w-full text-sm">
            <thead>
              <tr>
                <th className="border-b  border-dark-400 dark:border-dark-600 font-medium p-4 pl-8 pt-0 pb-3 dark:text-dark-200 text-left">
                  Дата
                </th>
                <th className="border-b  border-dark-400 dark:border-dark-600 font-medium p-4 pt-0 pb-3 dark:text-dark-200 text-left">
                  Сумма ₽
                </th>
                <th className="border-b  border-dark-400 dark:border-dark-600 font-medium p-4 pt-0 pb-3 dark:text-dark-200 text-left">
                  Платежная система
                </th>
                <th className="border-b  border-dark-400 dark:border-dark-600 font-medium p-4 pr-8 pt-0 pb-3 dark:text-dark-200 text-right">
                  Статус
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-dark-800">
              {payments.map((payment, index) => (
                <tr key={index}>
                  <td className="border-b  border-dark-400 dark:border-dark-700 p-4 pl-8 text-dark-800 dark:text-dark-400">
                    {timestampToDate(payment.created_at)}
                  </td>
                  <td className="border-b  border-dark-400 dark:border-dark-700 p-4 text-dark-800 dark:text-dark-400">
                    {payment.amount}
                  </td>
                  <td className="border-b  border-dark-400 dark:border-dark-700 p-4 text-dark-800 dark:text-dark-400">
                    {payment.payment_system_name}
                  </td>

                  <td className="border-b  border-dark-400 dark:border-dark-700 p-4 pr-8 text-dark-800 dark:text-dark-400 text-right">
                    {statusIcon(payment.status)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </Container>
  );
}
