import Container from "@/components/ui/Container/Container";
import Card from "@/components/ui/Card";
import { useState } from "react";

import CardForm from "./CardForm";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const FakePayment = ({ payment }) => {
  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    cardType: null,
  });

  return (
    <Container className="py-4 lg:py-10">
      <Card className="w-full lg:w-2/4 mx-auto">
        <header className="pb-3 mb-6 border-b border-dark-700">
          <small
            className={
              "inline-flex space-x-2 rounded-full px-2 mb-2 py-1 bg-green-300 text-green-800"
            }
          >
            <LockClosedIcon className={"w-4 h-4 "} />

            <span>
              {window.location.protocol + "//" + window.location.host} -
              безопасное соединение
            </span>
          </small>
          <small className={"text-dark-500 block"}>#{payment?.uuid}</small>
          <h1 className="text-2xl font-bold">
            {payment?.payment_system?.ru_name}
          </h1>
          <p>{payment?.payment_system?.description}</p>
        </header>
        <div className="mb-2">
          <small className="dark:text-dark-400">Сумма к оплате:</small>
          <p className={"text-4xl font-bold"}>
            {payment?.finally_amount?.toFixed(2)} ₽
          </p>
          <small className="dark:text-dark-400">
            Комиссия: {payment?.commission?.toFixed(2)} ₽ (
            {payment?.payment_system?.info?.commission}%)
          </small>
        </div>
        <div className="mb-5">
          <small className="dark:text-dark-400">Назначение платежа</small>
          <p className={""}>{`Номер договора: ${payment?.contract_number}`} </p>
        </div>
        <CardForm
          payment={payment}
          state={state}
          setState={setState}
          amount={payment?.finally_amount}
        />
        <small className={"mt-5 text-center text-dark-500 block"}>
          Процессинг обеспечивает Сбербанк ПАО
        </small>
      </Card>
      <div className={"flex justify-center items-center mt-4"}>
        <Link
          className="text-accent-500 hover:text-accent-600 hover:underline"
          to={`/order/${payment?.order}`}
        >
          Вернуться к заказу
        </Link>
      </div>
    </Container>
  );
};
export default FakePayment;
