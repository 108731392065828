import { cn } from "@/utils";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
export default function OrderPaymentsOption({
  payWay,
  RadioGroup,
  isDisabled,
}) {
  return (
    <RadioGroup.Option
      value={payWay}
      disabled={isDisabled}
      className={({ active, checked, disabled }) =>
        cn(
          "relative flex cursor-pointer rounded-lg px-4 py-3 shadow-md focus:outline-none",
          {
            "ring-2 ring-primary-400 ring-opacity-60 ring-offset-1 ring-offset-primary-500 border-transparent":
              active,
          },
          { "bg-dark-100 dark:bg-dark-700": !active },
          {
            "bg-dark-300 dark:bg-dark-900 bg-opacity-75": checked,
          },
          { "opacity-50 cursor-no-drop": disabled },
        )
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex w-full items-center justify-between">
            <div className="text-sm">
              <RadioGroup.Label as="div">
                <p
                  className={cn("font-bold mb-1", {
                    "dark:text-white": checked,
                    "text-dark-500 dark:text-dark-300": !checked,
                  })}
                >
                  {payWay?.ru_name}
                </p>
                <span className="text-xs">{payWay?.description}</span>
              </RadioGroup.Label>
              <RadioGroup.Description
                as="div"
                className={cn("text-dark-400", { "text-dark-700 dark:text-dark-300": checked })}
              >
                <ul className="flex space-x-1 items-center text-xs mt-2">
                  <li>
                    <InformationCircleIcon className="h-4 w-4 inline-block mr-1" />
                  </li>
                  <li className="">
                    <span className="">Комиссия: </span>
                    <span className="">{payWay?.info.commission}%</span>
                  </li>
                  <li className="">
                    <span className="">Мин. сумма: </span>
                    <span className="">{payWay?.info.min_deposit}</span>
                  </li>
                  <li className="">
                    <span className="">Макс. сумма: </span>
                    <span>{payWay?.info.max_deposit}</span>
                  </li>
                </ul>
              </RadioGroup.Description>
            </div>
            {checked && (
              <div className="shrink-0  text-accent-700">
                <span className="rounded-full flex bg-accent-400 p-1 border-2 border-accent-700">
                  <CheckIcon className="h-4 w-4 stroke-[4]" />
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
}
