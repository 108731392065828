import DynamicButton from "@/components/ui/DynamicButton";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Container from "@/components/ui/Container/Container";
import { timestampToDate } from "@/utils";
import { useOrderContext } from "@/context/OrderContext";

export default function OrderTrackHeader({ brand, uuid, orderDates, status }) {
  const { created, to } = orderDates;
  const { payHandler } = useOrderContext();

  return (
    <Container>
      <header className="flex flex-col lg:flex-row justify-between lg:items-center py-10 w-full">
        <div className="flex flexModify lg:items-center">
          <span className="font-bold text-2xl">{brand}</span>
          <ul className="flex flexModify">
            <li className="text-sm">
              <p className="dark:text-dark-100">ID заказа:</p>
              <p className="text-dark-600 dark:text-dark-400">{uuid}</p>
            </li>
            <li className="text-sm">
              <p className="dark:text-dark-100">Создано:</p>
              <p className="text-dark-600 dark:text-dark-400">{timestampToDate(created)}</p>
            </li>
            {(status === "created" || status === "pending") && (
              <li className="text-sm">
                <p className="text-accent-500">Бронь до:</p>
                <p className="text-accent-600">{timestampToDate(to)}</p>
              </li>
            )}
          </ul>
        </div>
        {(status === "created" || status === "pending") && (
          <div
            className={
              "fixed lg:static w-full lg:w-auto px-3 lg:px-0 bottom-3 left-0 z-50"
            }
          >
            <DynamicButton
              type="button"
              onClick={() => payHandler()}
              className={"w-full"}
            >
              <span className="flex space-x-2 justify-center items-center ">
                <span>Оплатить</span> <ArrowRightIcon className="w-5 h-5" />{" "}
              </span>
            </DynamicButton>
          </div>
        )}
      </header>
    </Container>
  );
}
